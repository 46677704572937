'use client'

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"

import type { PutBlobResult } from '@vercel/blob'
import { useState, useRef, useEffect } from 'react'
import { Button } from './ui/button'
import { Input } from './ui/input'
import Image from "next/image"
import { Card, CardContent } from "./ui/card"
import TagBadgeSelect from "./tag-badge-select"
import { ExtendedImage as PrismaImage } from '@/lib/image'
import { Tag } from "@prisma/client"

export default function UploadImageForm({
  className,
  initialImages = [],
  allTags,
  notTagSuggestions = false,
  onImagesChange
}: {
  className?: string,
  initialImages: PrismaImage[],
  allTags: Tag[],
  notTagSuggestions?: boolean,
  onImagesChange: (images: PrismaImage[]) => void
}) {
  const [images, setImages] = useState<PrismaImage[]>(initialImages)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [fileUploaded, setFileUploaded] = useState(false)

  useEffect(() => {
    setImages(initialImages)
  }, [initialImages])

  const [isLoading, setIsLoading] = useState(false)

  const updateImages = (newImages: PrismaImage[]) => {
    setImages(newImages)
    onImagesChange(newImages)
  }

  const handleSubmitUpload = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setIsLoading(true)
    
    try {
      if (!inputFileRef.current?.files) {
        throw new Error("No file selected")
      }

      const files = Array.from(inputFileRef.current.files)
      const formData = new FormData()
      files.forEach((file) => {
        formData.append('files', file)
      })

      const response = await fetch('/api/image/upload', {
        method: 'POST',
        body: formData,
      })

      const newBlobs = (await response.json()) as PutBlobResult[]

      const newImages = [
        ...images,
        ...newBlobs.map((blob) => ({
          id: '',
          url: blob.url,
          title: null,
          description: null,
          projectId: '',
          tags: []
        }))
      ]
      updateImages(newImages)
      setFileUploaded(false)
      if (inputFileRef.current) inputFileRef.current.value = ''
    } catch (error) {
      console.error("🔴 Error uploading images:", error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      <h3>Ajouter des images</h3>
      <Input name="file" ref={inputFileRef} type="file" multiple onChange={(e) => {
        setFileUploaded(e.target.value.length > 0)
      }} />

      {fileUploaded && (
        <Button type="button" onClick={handleSubmitUpload} loading={isLoading}>Upload</Button>
      )}

      {images.length > 0 && (
        <div className="w-full relative">
          <Carousel className="w-full px-12">
            <CarouselContent className="w-full">
              {
                images.map((image, index) => (
                  <CarouselItem key={index}>
                    <Card className="p-4">
                      <CardContent className="flex flex-col gap-4">
                        <div className="relative h-96">
                          <Image src={image.url} alt={image.url} fill objectFit="contain" />
                        </div>
                        <Input name="title" placeholder="Title" value={image.title ?? ''} onChange={(e) => {
                          const newImages = [...images]
                          newImages[index].title = e.target.value
                          updateImages(newImages)
                        }} />

                        <Input name="description" placeholder="Description" value={image.description ?? ''} onChange={(e) => {
                          const newImages = [...images]
                          newImages[index].description = e.target.value
                          updateImages(newImages)
                        }} />

                        {!notTagSuggestions && (
                          <TagBadgeSelect
                            allTags={allTags}
                            initialSelectedTags={image.tags}
                            accordionText="Ajouter un tag à l'image"
                            displayText="Tags de l'image"
                            className="bg-background"
                            onSelect={(tags) => {
                              const newImages = [...images]
                              newImages[index].tags = tags
                              updateImages(newImages)
                            }}
                          />
                        )}
                      </CardContent>
                    </Card>
                  </CarouselItem>
                ))
              }
            </CarouselContent>
            <CarouselPrevious type="button" className="left-0" />
            <CarouselNext type="button" className="right-0" />
          </Carousel>
        </div >
      )}
    </div>
  );
}