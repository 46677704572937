"use client"

import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

interface DatePickerProps {
  value?: Date
  placeholder?: string
  onValueChange: (date?: Date) => void
  className?: string
}

export default function DatePicker({ value, onValueChange, placeholder = 'Choisissez une date', className }: DatePickerProps) {
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false)

  return (
    <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal",
            !value && "text-muted-foreground",
            className
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value ? format(value, "dd/MM/yyyy") : <span>{placeholder}</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={value}
          onSelect={(date?: Date) => {
            onValueChange(date)
            setIsCalendarOpen(false)
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}

