'use client'

import React, { useEffect, useState } from 'react'
import { Badge } from './ui/badge'
import { Input } from './ui/input'
import Fuse from 'fuse.js'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './ui/accordion'
import { Plus, XIcon } from 'lucide-react'
import { Tag } from '@prisma/client'

const TagBadgeSelect = ({
  allTags,
  initialSelectedTags = [],
  accordionText = 'Ajouter un tag',
  displayText = 'Tags selectionnés',
  className,
  onSelect
}: {
  allTags: Tag[],
  initialSelectedTags: Tag[],
  accordionText?: string,
  displayText?: string,
  className?: string,
  onSelect: (selectedTags: Tag[]) => void
}) => {
  const [selectedTags, setSelectedTags] = useState<Tag[]>(initialSelectedTags)
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSelectedTags(initialSelectedTags)
  }, [initialSelectedTags])

  const [displayedTag, setDisplayedTag] = useState(allTags.filter(tag => !initialSelectedTags.find(selectedTag => selectedTag.id === tag.id)))

  useEffect(() => {
    let filteredTag = allTags

    if (selectedTags.length > 0) {
      filteredTag = filteredTag.filter(tag => !selectedTags.find(selectedTag => selectedTag.id === tag.id))
    }

    if (search) {
      const fuse = new Fuse(filteredTag, { keys: ['name'] })
      const searchResults = fuse.search<Tag>(search)
      filteredTag = searchResults.map(result => result.item)
    }

    setDisplayedTag(filteredTag)
  }, [search, selectedTags])

  const handleSelectTag = (tag: Tag) => {
    setSelectedTags((currentSelectedTags) => {
      const curentSelectedTagsIds = currentSelectedTags.map(tag => tag.id)
      const updatedSelectedTags = curentSelectedTagsIds.includes(tag.id)
        ? currentSelectedTags.filter(selectedTag => selectedTag.id !== tag.id)
        : [...currentSelectedTags, tag]

      onSelect(updatedSelectedTags)
      return updatedSelectedTags
    })
  }

  return (
    <div className={`w-full flex flex-col gap-4 `}>
      {selectedTags.length > 0 && (
        <div>
          <h4>{displayText} :</h4>
          <div className='flex flex-wrap gap-2'>
            {selectedTags.map((tag) => (
              <Badge
                key={tag.id}
                onClick={() => handleSelectTag(tag)}
                className="cursor-pointer hover:bg-destructive group"
              >
                <XIcon className=" h-4 w-4 hidden group-hover:flex" />{tag.name}
              </Badge>
            ))}
          </div>
        </div>
      )}

      <Accordion type="single" collapsible className={className}>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <Plus /> {accordionText}
          </AccordionTrigger>
          <AccordionContent className='flex flex-col gap-3'>
            <Input placeholder="Rechercher..." className='w-full flex-1' value={search} onChange={(e) => setSearch(e.target.value)} />
            <div className='flex flex-wrap gap-2 justify-between'>
              {displayedTag.map((tag) => (
                <Badge
                  key={tag.id}
                  onClick={() => handleSelectTag(tag)}
                  className="cursor-pointer"
                >
                  {tag.name}
                </Badge>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>


    </div>
  )
}

export default TagBadgeSelect